<template>
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{nombre}} 
                </v-card-title>
                <v-card-text class="pt-2">
                    <!-- ---------------------------------------------------- -->
                    
                    <v-select
                        v-model="vals.percepcion"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.percepcion"
                        label="PERCEPCION"
                        :persistent-hint=true
                        hint='Capacidad para reaccionar ante una molestia relacionada con presión'
                        
                    ></v-select>

                    <v-select
                        v-model="vals.humedad"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.humedad"
                        label="HUMEDAD"
                        :persistent-hint=true
                        hint='Nivel de exposición de la piel a la humedad'
                    ></v-select>

                    <v-select
                        v-model="vals.actividad"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.actividad"
                        label="ACTIVIDAD"
                        :persistent-hint=true
                        hint='Nivel de actividad física'
                    ></v-select>

                    <v-select
                        v-model="vals.movilidad"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.movilidad"
                        label="MOVILIDAD"
                        :persistent-hint=true
                        hint='Capacidad para cambiar y controlar la posición del cuerpo
'
                    ></v-select>

                    <v-select
                        v-model="vals.nutricion"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.nutricion"
                        label="NUTRICION"
                        :persistent-hint=true
                        hint='Patrón usual de ingesta de alimentos'
                    ></v-select>

                    <v-select
                        v-model="vals.roce"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.roce"
                        label="ROCE"
                        :persistent-hint=true
                        hint='Peligro de roce y lesión cutanea'
                    ></v-select>

                    <v-text-field
                        v-model="observaciones"
                        
                        clearable
                        dense
                        label="Observaciones"
                    ></v-text-field>
                    
                    <v-expand-transition>
                        <div v-if="sumaValoracion>0" class="text-center">
                            <div class="title">Puntaje: <span>{{sumaValoracion}}</span></div>
                            <div class="font-weight-black" :style="'color: ' + colorValoracion">{{textoValoracion}}</div>
                        </div>
                    </v-expand-transition>

                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    
                    <v-btn
                        color="success"
                        :loading="loading"
                        :disabled="!sumaValoracion"
                        @click="agrega"
                    >
                        Agregar
                    </v-btn>
                    
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,
        estaFecha: String,
        internamiento:Number,
    },

    data:()=>({
        loading:false,
        observaciones:'',
        vals:{
            percepcion:0,
            humedad:0,
            actividad:0,
            movilidad:0,
            nutricion:0,
            roce:0,
            
        },
        itemsEscala:{
            percepcion:[
                {value:1, text: 'Sin percepción'},
                {value:2, text: 'Muy poca'},
                {value:3, text: 'Poca'},
                {value:4, text: 'Sin limitaciones'},
                
            ],
            humedad:[
                {value:1, text: 'Constante'},
                {value:2, text: 'Frecuente'},
                {value:3, text: 'Ocacional'},
                {value:4, text: 'Rara'},
                
            ],
            actividad:[
                {value:1, text: 'Encamado'},
                {value:2, text: 'En silla'},
                {value:3, text: 'Camina poco'},
                {value:4, text: 'Camina mucho'},
            ],
            movilidad:[
                {value:1, text: 'Inmovil'},
                {value:2, text: 'Limitada'},
                {value:3, text: 'Ligeramente limitada'},
                {value:4, text: 'Ilimitada'},
                ],
            nutricion:[
                {value:1, text: 'Muy pobre'},
                {value:2, text: 'Inadecuada'},
                {value:3, text: 'Adecuada'},
                {value:4, text: 'Excelente'},
            ],
            roce:[
                {value:1, text: 'Problema'},
                {value:2, text: 'Potencial'},
                {value:3, text: 'Sin problema'},
            ],
        },
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                this.$emit('update:dialogDe',val)
            },
        },
        sumaValoracion(){
            if (this.vals.percepcion>0 && this.vals.humedad>0 && this.vals.actividad>0 && this.vals.movilidad>0 && this.vals.nutricion>0 && this.vals.roce>0){
                return (parseInt(this.vals.percepcion) + parseInt(this.vals.humedad) + parseInt(this.vals.actividad) + parseInt(this.vals.movilidad) + parseInt(this.vals.nutricion) + parseInt(this.vals.roce))
            } else {
                return null
            }
        },
        textoValoracion(){
            if (this.sumaValoracion){
                let text = ''
                if (this.sumaValoracion < 12)
                    text = 'Riesgo ALTO'
                if (this.sumaValoracion >=12  && this.sumaValoracion <= 14)
                    text = 'Riesgo MODERADO'
                if (this.sumaValoracion > 14)
                    text = 'Riesgo BAJO'
                return text
            } else {
                return null
            }
        },
        colorValoracion(){
            let text = ''

                if (this.sumaValoracion < 12)
                    text = 'red'
                if (this.sumaValoracion >=12  && this.sumaValoracion <= 14)
                    text = 'orange'
                if (this.sumaValoracion > 14)
                    text = 'green'
            return text
        }
    },
    methods:{
        cancela(){
            this.vals.percepcion = this.vals.humedad = this.vals.actividad = this.vals.movilidad = this.vals.nutricion = this.vals.roce = 0
            this.observaciones = ''
            this.dialog = false
        },
        async agrega(){

            this.loading=true

            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'upp',
                valor: JSON.stringify(this.vals),
                internamiento:this.internamiento,
                observaciones: this.observaciones
            }
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    this.vals.percepcion = this.vals.humedad = this.vals.actividad = this.vals.movilidad = this.vals.nutricion = this.vals.roce = 0
                    this.observaciones = ''
                    this.loading = false
                    this.dialog = false

                } else {
                    console.log(dt.data)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }





            //console.log ('Actualizando UPP en en turno '+this.turno+' con los valores '+ this.vals)
            //this.vals.percepcion = this.vals.humedad = this.vals.actividad = this.vals.movilidad = this.vals.nutricion = this.vals.roce = 0
            //this.$emit('escalaSuccess')
            //this.dialog = false
            
        },
    },
    
}
</script>

<style>

</style>